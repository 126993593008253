export const ARTICLES_LIST_REQUEST = "ARTICLES_LIST_REQUEST";
export const ARTICLES_LIST_SUCCESS = "ARTICLES_LIST_SUCCESS";
export const ARTICLES_LIST_FAIL = "ARTICLES_LIST_FAIL";

export const ARTICLES_CREATE_REQUEST = "ARTICLES_CREATE_REQUEST";
export const ARTICLES_CREATE_SUCCESS = "ARTICLES_CREATE_SUCCESS";
export const ARTICLES_CREATE_FAIL = "ARTICLES_CREATE_FAIL";

export const ARTICLES_UPDATE_REQUEST = "ARTICLES_UPDATE_REQUEST";
export const ARTICLES_UPDATE_SUCCESS = "ARTICLES_UPDATE_SUCCESS";
export const ARTICLES_UPDATE_FAIL = "ARTICLES_UPDATE_FAIL";

export const ARTICLES_DELETE_REQUEST = "ARTICLES_DELETE_REQUEST";
export const ARTICLES_DELETE_SUCCESS = "ARTICLES_DELETE_SUCCESS";
export const ARTICLES_DELETE_FAIL = "ARTICLES_DELETE_FAIL";
